.App {
  text-align: center;
  width: 100%;
  height: 100%;
}


header.header {
  color: white;
  width: 100%;
}

.Page {
    > .right {
        color: $black;
    }
}

.sidebar {
    .selected {
        border-left: 10px solid $primaryColor;
        padding-left: calc(2em - 5px);
        font-weight: bold;
    }

    a {
        color: $primaryColor;
        padding-left: 2em;
        border-left: 5px solid $primaryColor;
        padding-top: 0.2em;
        padding-bottom: 0.2em;
        margin-bottom: 0px;

        &:hover {
            border-left: 10px solid $primaryColor;
            padding-left: calc(2em - 5px);
        }
    }
}

.contenteditable {
    width: fit-content;
    min-width: 20px;
    max-width: 100%;
    word-break: break-word;
    white-space: pre-wrap;
    overflow: hidden;
    cursor: pointer;
}
.rdw-editor-main {overflow: visible;}

.card {
    box-shadow: #ababab5e 0px 4px 11px -2px;
    width: 98%;
    margin: auto;
    font-size: 0.875rem;

    h6 {font-size: 0.875rem;}
    small { font-size: 0.75rem;  }
    .completed {
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        span {
            display: inline-block;
            width: 3rem;
            height: 3rem;
            border-radius: 3rem;
            background-color: #29bf7c;
        }
    }
}


.Locations {
    .card {
        box-shadow: none;
        address {
            border-bottom-width: 2px !important;
        }
    }
    .card-img-top {
        border-color: $lightGrey !important;
        border-width: 2px !important;
    }
    .card-body {
        flex: 0;
    }
}

.card-img {
    height: 166px;
    background-color: #d0d0d0;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    box-shadow: inset 0px 18px 20px #0000003d, inset 0px -18px 20px #0000003d;
}

.card-img-top {
    min-height: 120px;
    max-height: 150px;
    background-color: $white;
    object-fit: cover;
}

.task-child {
    width: 12em;
    margin: auto;

    .child-header {
        background: #dadada;
        padding: 0.3em;
        font-size: 10px;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.8;
        letter-spacing: normal;

        p {
            margin-bottom: 0;
            color: $primaryColor;
            text-transform: uppercase;
        }
    }

    .card-footer {
        padding: 0 !important;
    }
}

.dropzone:hover {
    border: 1px solid $primaryColor !important;
}

.dropzone .inner {
    display: flex;
    height: 100%;
}

.inner span {
    text-align: center;
    align-self: center;
    width: 100%;
}

.attachment:hover > button {
    display: block !important;
}

h1, h2 { color: $primaryColor; font-size: 1.75rem; font-weight: 600; font-style: normal; line-height: normal; letter-spacing: 0.6px;  }
h3 { color: $primaryColor; font-size: 1.15rem; font-weight: bold; font-style: normal; line-height: normal; letter-spacing: 0.6px;  }
h4 { color: $darkGrey; font-size: 1.15rem; font-weight: 600; font-style: normal; line-height: normal; letter-spacing: 0.8px;  }
a { color: $primaryColor;  }

.border { border: 1px solid $primaryColor!important; }
.c-pointer { cursor: pointer; }

.modal-dialog {
  max-width: 100%;
}

img {
  max-width: 100%;
}

.CurrentTask {
  background: #fff;
  font-size: 0.875rem;
}
.modal-dialog .modal-content { background-color: transparent; border: 0; }
header .dropdown button {padding: 0;}

.Locations {
    .jamppa {
        width: 11rem;
        position: absolute;
        left: 2rem;
        margin-top: -7rem;
    }
}

.Year {
    position: relative;

    &:after {
        position: absolute;
        width: 0.1em;
        margin-top: 0.2rem;
        height: calc(100% - 3.5rem);
        border-left: 1px solid $grey;
        content: "";
        left: 50%;
        top: 4.4rem;
        z-index: -1;
    }

}

.btn-outline-primary {
    color: $primaryColor;

    &:hover {
        color: #fff !important;
        background-color: $primaryColor !important;
    }
}

div.message {
    white-space: pre-line;
}

.slider {
    -webkit-appearance: none;
    width: 90%;
    margin: auto;
    height: 2px;
    border-radius: 5px;
    background: #d3d3d3;
    outline: none;
    opacity: 0.7;
    -webkit-transition: .2s;
    transition: opacity .2s;
}

.slider:hover {
    opacity: 1;
}

.slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 1px;
    height: 1px;
    padding: 2px;
    background: $primaryColor;
    border-radius: 50%;
    border: 6px solid white;
    box-shadow: 0 0px 0px 1px $primaryColor;
    cursor: pointer;
}

.slider::-moz-range-thumb {
    width: 1px;
    height: 1px;
    padding: 2px;
    background: $primaryColor;
    border-radius: 50%;
    border: 6px solid white;
    box-shadow: 0 0px 0px 1px $primaryColor;
    cursor: pointer;
}

.slider-years > p {
    width: 26px;
    height: 19px;
    font-size: 10px;
    font-weight: 600;
    text-align: center;
    color: $primaryColor;
    margin-top: 0.4em;
}

.income-field > div {
    padding-bottom: 0.4em;
    border-bottom: 1px solid #80808075;
}

.income-field > div > p {
    color: #808080;
    margin-top: 1em;
}

.income-field > div > input {
    border: 1px solid $primaryColor;
    height: 2.4em;
    width: 7em;
    min-width: 4em;
    color: $primaryColor;
}

.income-row > .trash {
    display: none;
    align-self: center;
    position: absolute;
    margin-left: 49%;
    fill: $primaryColor !important;
}

.income-row:hover > .trash {
    display: block;
}

.budget-defaults {
    position: absolute;
    background: $lightGrey;
    box-shadow: 6px 3px 3px 0 rgba(40, 40, 40, 0.2);
    margin: auto;
    padding: 0.4em;
    left: 0;
    right: 0;
    bottom: 15%;
    width: 424px;
    height: 66px;
    font-size: 12px;
}

.header-link {
    font-size: 0.875rem !important;

    &:hover {
        text-decoration: none;
        color: $primaryColor;
    }
    &.btn-primary, &.btn-outline-primary {
        &:hover {
            color: $white !important;
        }
    }

    span {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }
}

.text-bold {
    font-weight: 600 !important;
}

.custom-select {
    border: 1px solid $primaryColor !important;
    color: $primaryColor !important;
    padding-top: 0.2em !important;
    font-weight: 600;
}

.task-information ul {
    padding-left: 2em;
    list-style: disc outside;
}
.task-information li {
    padding-bottom: 0.4em;
    font-size: 12px;
    word-break: break-word;
}

.form-check label {
    color: $primaryColor;
    font-size: 0.75rem;
    line-height: 1.1;
}

.tag-container {
    background: $lightGrey;
    margin: 1px;
    font-size: 0.875rem;
    h6::first-letter {
        text-transform: capitalize;
    }
}

.box {
    position: absolute;
    margin-left: 49%;
    border-radius: 4px;
    border: 1px solid $primaryColor;
    color: $primaryColor !important;
    width: 2em;
    height: 2em;
    display: flex;
    align-items: center;
    justify-content: center;
}

.box:hover {
    color: white !important;
    background: $primaryColor;
}


.field-error {
    .contenteditable {
        color: red;
        font-weight: bold;
    }
}
.field-pending {
    .contenteditable {
        color: #0000008c;
        font-weight: bold;
    }
}

.ck .ck-balloon-panel {
    z-index: 1051;
}

.split-container {
    background: $lightGrey;
    box-shadow: 0 0 3px 0 rgba(40, 40, 40, 0.2);
    position: absolute;
    width: 15rem;
    border-radius: 2px;
    left: 8rem;
}

.rbt-input:not(:focus) {
    border: 0;
    background-color: transparent;
    text-align: inherit;
}

.rbt-input-hint-container {
    width: 4em;

    * {
        border: 0;
        color: black;
        font-size: 16px;
        font-weight: 400;
        padding: 0 !important;
        height: inherit !important
    }
}

.rbt-unset-width {
    .rbt-input-hint-container {
        width: 100%;
    }
}

*:hover > .icon-s:not(.nohover) {
    stroke: white;
}
*:hover > .icon-f:not(.nohover) {
    fill: white;
    .cls-2 {
        fill: $primaryColor;
    }
    .cls-4 {
        fill: $primaryColor;
    }
}
*:hover > .icon-fs:not(.nohover) {
    fill: white;
    stroke: white;
    .cls-2 {
        fill: $primaryColor;
    }
    .cls-4 {
        fill: $primaryColor;
    }
}

*:hover > .icon-cls:not(.nohover) {
    .cls-2 {
        fill: none;
    }
}

.icon-s {
    width: 20px;
    stroke: $primaryColor;
}

.icon-fs {
    width: 20px;
    stroke: $primaryColor;
    fill: $primaryColor;
}

.icon-f {
    width: 20px;
    fill: $primaryColor;
}

.icon-s-inv {
    width: 20px;
    stroke: white;
}

.icon-fs-inv {
    width: 20px;
    stroke: white;
    fill: white;
}

.icon-f-inv {
    width: 20px;
    fill: white;
}

.icon-cls {
    .cls-2 {
        fill: none;
    }
}

.LandingPage {
    > div {
        padding-top: 0;
    }

    .leader-group {
        img {
            &.behind {
                width: 72%;
            }
            &.front {
                width: 68%;
                right: 0;
            }
            &.jamppa {
                max-width: 21%;
                right: 6%;
            }
        }
    }
}

.services-navigation {
    margin-top: 0;
}
@media screen and (max-width: 1430px) {
    .services-navigation {
        margin-top: 3em;
    }
}


[contenteditable=true]:empty:before { content: attr(placeholder); display: block; color: #aaa; }
