$primaryColor : #002b17;
$secondaryColor : #264b3a;
$lightGrey : #f7f7f7;
$darkGrey: #929292;
$grey : #d8d8d8;
$black : #000;
$white : #fff;
$yeallow : #f8e71c;

$theme-colors: (
    "primary": $primaryColor,
    "secondary": $secondaryColor,
    "bg-light": $lightGrey,
    "gray": $grey,
);
@import '~bootstrap/scss/bootstrap.scss';
@import '~react-toastify/dist/ReactToastify.css';

$sizes: (
    25: 25%,
    50: 50%,
    75: 75%,
    100: 100%,
    auto: auto,
);

h1, h2, h3, h4, h5, h6 {
    color: $primaryColor;
}

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
    @each $prop, $abbrev in (width: w, height: h) {
      @each $size, $length in $sizes {
        .#{$abbrev}#{$infix}-#{$size} { #{$prop}: $length !important; }
      }
    }
  }
}

html, body {
    min-width: 100%;
    min-height: 100%;
    width: 100%;
    height: 100%;
}
body {
    margin: 0;
    padding: 0;
    background-color: #fff;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
      "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    line-height: 1.438rem;
    letter-spacing: 0.5px;
    font-size: 16px;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

@include media-breakpoint-between(sx, lg) {
    .container {
        width: 100%;
        max-width: none;
    }
}

#root {
    height: 98%;
    max-height: 98%;

    > div {
        height: 100%;
    }
}

.App > div {
    padding-top: 13rem;

    @include media-breakpoint-up(md) {
        padding-top: 9rem;
    }

    &.Locations {
        padding-top: 3rem;
    }
}

header .dropdown-menu {
    background-color: $lightGrey;

    a {
        color: $primaryColor;
    }

    .dropdown-item {
        border-bottom: 1px solid $grey;
        font-size: 0.875rem;

        &:last-child {
            border-bottom: none;
        }
    }
}

.highcharts-xaxis-grid .highcharts-grid-line {
    stroke-width: 2px;
    stroke: #eee;
}

.btn, .custom-select {
    border-radius: 2px;
}

.custom-select:disabled,
.btn-outline-primary.disabled,
.btn-outline-primary:disabled,
.btn-link:disabled > div, .btn-link.disabled > div{
    background: $lightGrey;
    border-color: $darkGrey !important;
    color: $darkGrey !important;
}

.Toastify__toast {
    padding: 0;
    box-shadow: none;

    &.Toastify__toast--success {
        background: transparent;
        img {
            width: 82px;
            position: absolute;
            z-index: 999999;
        }
        .message {
            padding-left: 90px;
        }
    }
    &.Toastify__toast--error {
        background: transparent;
        .message {
            padding: 1rem;
        }
    }

    .Toastify__close-button.Toastify__close-button--success {
        display: none;
    }

    .custom-notification {
        min-height: 100px;

        .message {
            background: $primaryColor;
            min-height: 3.5rem;

            &.success { background-color: #29bf7c; }
            &.error { background-color: #e74c3c; }
        }
    }
}

.start-steps {
    .step-header {
        width: 7rem;
        color: $primaryColor;

        .circle {
            width: 4.5rem;
            height: 4.5rem;
            > * { color: $primaryColor; }
        }

        &.current {
            .circle {
                background-color: $primaryColor;
                > * { color: $white; }
            }
        }
    }

    .step-container {
        background-color: $lightGrey;
        border-top: 1px solid $grey;

        input::placeholder {
            text-transform: capitalize;
        }

        .plan {
            border: 3px solid $primaryColor;

            .rounded-circle {
                border-width: 3px !important;
            }
            .icon-check {
                fill: $primaryColor;
            }
        }

        .rbt-input:not(:focus) {
            border: 0;
            background-color: transparent;
            text-align: left !important;
        }
        .rbt-input-hint-container {
            width: auto;
        }
    }

    input, .custom-select {
        &.error {
            background-color: $yeallow;
        }
    }

    .zipSearch {
        &.error {
            background-color: $yeallow;
            .rbt-input-hint-container, input {
                background-color: $yeallow;
            }
        }
    }
}

.btn-text {background: transparent;}

@import './App';

@import './ie';
