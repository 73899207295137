@mixin target-IE {
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    @content;
  }
}

img {
    @include target-IE {
        max-height: 100%;
    }
}
header img.logo {
    @include target-IE {
        height: 6rem;
    }
}
input.slider {
    @include target-IE {
        height: 20px;
        padding: 0px;
    }
}
.btn-outline-primary {
    @include target-IE {
        background-color: $white;
    }
}
.btn-link {
    @include target-IE {
        background: transparent;
    }
}

.icon-s, .icon-fs, .icon-f, .icon-s-inv, .icon-fs-inv, .icon-f-inv {
    @include target-IE {
        height: 20px;
    }
}

.Location > .btn-outline-primary {
    @include target-IE {
        height: 51px;
    }
}

.Locations .jamppa {
    @include target-IE {
        left: 0rem;
        top: 7rem;
    }
}

.Notifications {
    img.logo {
        @include target-IE {
            height: 63px;
        }
    }
    .modal-content {
        @include target-IE {
            background: transparent;
        }
    }

    img.jamppa {
        @include target-IE {
            display: none;
        }
    }

    .stacked {
        h1 {
            @include target-IE {
                margin-left: 4rem;
            }
        }
        p:first-child {
            @include target-IE {
                padding-left: 4rem;
            }
        }
    }
}

.Invite {
    content {
        > div {
            @include target-IE {
                width: 100% !important;
                margin: 0 !important;
            }
        }

        label {
            + div {
                @include target-IE {
                    width: 100% !important;
                }

                input[type="email"] {
                    @include target-IE {
                        width: 100%;
                    }
                }
            }
        }
    }
}

.LandingPage {
    .navbar-brand {
        @include target-IE {
            width: 230px;
        }
    }
    .leader-group {
        img {
            &.front {
                @include target-IE {
                    bottom: 0;
                }
            }
        }
    }
    .jamppa-plans {
        @include target-IE {
            width: 174px;
        }
    }

}

.Locations {
    .card-body {
        @include target-IE {
            min-height: 40px;
        }
    }
}